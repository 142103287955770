import {
	Persona,
	PersonaSize,
	PrimaryButton,
	Stack,
	useTheme,
} from '@fluentui/react';

import {useTranslation} from 'react-i18next';
import {useStore} from 'react-context-hook';
import React, {useState} from 'react';
import {getLocaleForDateFns} from 'i18n/localeDateFormat';

import {format} from 'date-fns';
import _ from 'lodash';
import {FaqReplies} from './FaqReplies';
import {CreateReply} from './CreateReply';

export const FaqPanelContent: any = () => {
	const [selectedQuestion, setSelectedQuestion] = useState<any[] | null>(null);
	const [faq, setFaq] = useStore<any[]>('faq', []);
	const {t, i18n} = useTranslation('common/faq');
	const theme = useTheme();
	const [showReplyDialog, setShowReplyDialog] = useState(false);
	const locale: Locale = getLocaleForDateFns(i18n.language);

	return (
		<div
			style={{
				display: 'flex',
				gap: '10px',
				height: '100%',
				marginTop: '20px',
			}}
		>
			<div style={{width: '50%'}}>
				{faq?.map(elem => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
							marginBottom: '10px',
						}}
						key={Math.random() + elem.createdAt}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								padding: '10px',
							}}
						>
							<strong>
								<Persona
									aria-label='persona'
									size={PersonaSize.size24}
									text={elem.createdBy.name}
								/>
							</strong>
							<div
								style={{
									color: theme.palette.neutralDark,
									opacity: '0.7',
									marginRight: '8px',
								}}
							>
								|
							</div>
							<div style={{color: theme.palette.neutralDark, opacity: '0.7'}}>
								{format(new Date(elem.createdAt), 'dd LLLL yyyy HH:mm aa', {
									locale,
								})}
							</div>
						</div>

						<Stack.Item style={{padding: '10px'}}>{elem.content}</Stack.Item>
						<Stack.Item onClick={() => setSelectedQuestion(elem.answers)}>
							<div
								style={{
									backgroundColor: theme.palette.neutralLight,

									padding: '10px',
									cursor: 'pointer',
									color: theme.palette.red,
								}}
							>
								{elem.answers.length ? (
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<FaqReplies answers={elem.answers} />
									</div>
								) : (
									t('NoReplies')
								)}
							</div>
						</Stack.Item>
					</div>
				))}
			</div>
			<div
				style={{
					width: '50%',
					height: '100%',
					boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
					backgroundColor: `${theme.palette.neutralLight}`,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<div>
					{selectedQuestion
						? selectedQuestion?.map((elem: any) => (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									key={Math.random() + elem.createdAt}
								>
									<Stack.Item>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',

												padding: '10px',
											}}
										>
											<strong>
												<Persona
													aria-label='persona'
													size={PersonaSize.size24}
													text={elem.createdBy.name}
												/>
											</strong>
											<div
												style={{
													color: theme.palette.neutralDark,
													opacity: '0.7',
													marginRight: '8px',
												}}
											>
												|
											</div>
											<div
												style={{
													color: theme.palette.neutralDark,
													opacity: '0.7',
												}}
											>
												{format(
													new Date(elem.createdAt),
													'dd LLLL yyyy HH:mm aa',
													{
														locale,
													},
												)}
											</div>
										</div>
									</Stack.Item>
									<Stack.Item
										style={{marginBottom: '20px', paddingLeft: '10px'}}
									>
										{elem.content}
									</Stack.Item>
								</div>
						  ))
						: ''}
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						padding: '10px',
					}}
				>
					<PrimaryButton
						style={{marginRight: '10px'}}
						onClick={() => setShowReplyDialog(true)}
						disabled={!selectedQuestion}
						iconProps={{
							iconName: 'PageEdit',
						}}
					>
						{t('PostReply')}
					</PrimaryButton>
				</div>
			</div>
			<CreateReply
				showDialog={showReplyDialog}
				setShowDialog={setShowReplyDialog}
			/>
		</div>
	);
};

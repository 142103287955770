import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
	IDialogProps,
	IPersona,
	PrimaryButton,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import React, {useEffect} from 'react';
import {LabelWithTooltip} from 'features/localizedTooltips';
import {PeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {Requirement, RequirementChangeType, User, UserRole} from 'types';
import {EnumDropdown} from 'components/hookForms';
import {useForm} from 'react-hook-form';
import {ReasonField} from 'features/RegulatoryDocuments/components/DocumentDetails/WorkflowDialog/ReasonField';
import {ActionButton} from 'components';

export enum SendApprovalDialogChangeTypeMode {
	Show,
	Hide,
	Disable,
}

export type SendApprovalDialogProps = Omit<IDialogProps, 'modalProps'> & {
	onConfirm: () => void;
	requirementName: string;
	defaultApprovers: User[];
	users: User[]; // Add users state as a prop
	setUsers: (users: User[]) => void; // Add setUsers as a prop
	changeType: RequirementChangeType;
	setChangeType: (changeType: RequirementChangeType) => void;
	changeTypeMode: SendApprovalDialogChangeTypeMode;
	reason: string;
	setReason: (res: string) => void;
};

export const SendApprovalDialog: React.FC<SendApprovalDialogProps> = ({
	defaultApprovers,
	requirementName,
	users,
	setUsers,
	changeType,
	setChangeType,
	changeTypeMode,
	reason,
	setReason,

	...props
}) => {
	const {t} = useTranslation('features/requirements', {
		keyPrefix: 'WorkflowDialog',
	});
	const onUserInputChange = React.useCallback(
		(items: any) => setUsers(items || []),
		[users],
	);

	const [submitDisabled, setSubmitDisabled] = React.useState(true);
	const [peoplePickerDisabled, setPeoplePickerDisabled] = React.useState(false);

	const [peoplePickerRoleFilter, setPeoplePickerRoleFilter] = React.useState([
		UserRole.ShApprover,
	]);
	const [defaultSelectedApprovers, setDefaultSelectedApprovers] =
		React.useState<IPersona[]>([]);
	const [peoplePickerKey, setPeoplePickerKey] = React.useState(0);

	const dismiss = () => {
		props?.onDismiss?.();
	};
	const onInputChange = React.useCallback(
		(_: any, val?: string) => setReason(val ?? ''),
		[reason],
	);

	const dialogContentProps: IDialogContentProps = React.useMemo(
		() => ({
			type: DialogType.normal,
			title: t('Approval'),
			subText: t('ConfirmApprovalText', {name: requirementName}),
		}),
		[],
	);
	const handleSubmitDisabled = () => {
		const usersRequired = (users?.length || 0) === 0;
		const changeTypeRequired =
			changeTypeMode === SendApprovalDialogChangeTypeMode.Show &&
			changeType === RequirementChangeType.None;
		setSubmitDisabled(usersRequired || changeTypeRequired);
	};

	React.useEffect(() => {
		handleSubmitDisabled();
	}, [users]);

	React.useEffect(() => {
		if (changeType === RequirementChangeType.Editorial) {
			setDefaultSelectedApprovers([]);
			setUsers([]);
			setPeoplePickerRoleFilter([UserRole.ShApprover, UserRole.Vex]);
			setPeoplePickerDisabled(false);
		} else {
			setDefaultSelectedApprovers(
				defaultApprovers?.map((v: User) => ({
					...v,
					text: v?.name,
				})),
			);
			setUsers(defaultApprovers);
			setPeoplePickerRoleFilter([UserRole.ShApprover]);
			setPeoplePickerDisabled(defaultApprovers?.length >= 1);
		}

		handleSubmitDisabled();
		setPeoplePickerKey(Math.random());
	}, [changeType]);

	const modalProps = {
		isBlocking: true,
		styles: {main: {maxWidth: 450}},
	};
	type FormFields = Pick<Requirement, 'changeType'>;
	const {control, getValues} = useForm<FormFields>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});
	const onChangeTypeChange = () => {
		const changeType = getValues('changeType');
		setChangeType(changeType);
	};

	return (
		<Dialog
			dialogContentProps={dialogContentProps}
			{...props}
			onDismiss={dismiss}
			modalProps={modalProps}
		>
			<>
				{changeTypeMode !== SendApprovalDialogChangeTypeMode.Hide && (
					<EnumDropdown
						required={true}
						control={control}
						name={'changeType'}
						label={t('ApprovalChangeTypeLabel')}
						placeholder={t('ApprovalChangeTypePlaceholder')}
						defaultValue={changeType}
						enumName={'RequirementChangeType'}
						enumType={RequirementChangeType}
						exclude={[RequirementChangeType.None]}
						onChange={onChangeTypeChange}
						disabled={
							changeTypeMode === SendApprovalDialogChangeTypeMode.Disable
						}
					/>
				)}
				<LabelWithTooltip translationKey='users'>
					{t('ChoosePersonLabel')}
				</LabelWithTooltip>
				<PeoplePicker
					key={peoplePickerKey}
					onChange={items => onUserInputChange(items ?? [])}
					itemLimit={1}
					excludeCurrentUser={true}
					defaultSelectedItems={defaultSelectedApprovers}
					disabled={peoplePickerDisabled}
					roles={peoplePickerRoleFilter}
				/>
				<ReasonField
					onChange={onInputChange}
					multiline
					autoAdjustHeight
					maxLength={488}
					label={t('CommentField')}
				/>
				<DialogFooter>
					<PrimaryButton onClick={props.onConfirm} disabled={submitDisabled}>
						{t('ApprovalConfirmButton')}
					</PrimaryButton>
					<DefaultButton onClick={dismiss} text={t('CancelButton')} />
				</DialogFooter>
			</>
		</Dialog>
	);
};

import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPogisDocumentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPogisDocumentsQuery = { __typename?: 'Query', pogisDocuments: Array<{ __typename?: 'PogisDocument', associations?: Array<string> | null, createdAt?: any | null, docStatus: string, editor: string, id: string, keywords: Array<string>, linkVersion?: string | null, markets: Array<string>, modifiedAt?: any | null, pogisId: string, regulationStatus: string, relatedDocuments?: Array<string> | null, shortInfo: string, standards?: Array<string> | null, pogisAttachments?: Array<{ __typename?: 'AttachmentRef', attachmentId: string, file: { __typename?: 'BlobRef', fileName: string, uri: any }, category?: { __typename?: 'AttachmentCategory', name: string } | null }> | null, dates: Array<{ __typename?: 'PogisDate', date: any, dateType: string }> }> };

export type CreatePogisDocumentMutationVariables = Types.Exact<{
  input: Types.CreatePogisDocumentInput;
}>;


export type CreatePogisDocumentMutation = { __typename?: 'Mutation', createPogisDocument: { __typename?: 'PogisDocumentPayload', pogisDocument: { __typename?: 'PogisDocument', id: string } } };

export type UpdatePogisDocumentMutationVariables = Types.Exact<{
  input: Types.UpdatePogisDocumentInput;
}>;


export type UpdatePogisDocumentMutation = { __typename?: 'Mutation', updatePogisDocument: { __typename?: 'PogisDocumentPayload', pogisDocument: { __typename?: 'PogisDocument', id: string } } };


export const GetPogisDocumentsDocument = gql`
    query GetPogisDocuments {
  pogisDocuments {
    associations
    createdAt
    docStatus
    editor
    id
    keywords
    linkVersion
    markets
    modifiedAt
    pogisId
    regulationStatus
    relatedDocuments
    shortInfo
    standards
    pogisAttachments {
      file {
        fileName
        uri
      }
      attachmentId
      category {
        name
      }
    }
    dates {
      date
      dateType
    }
  }
}
    `;

/**
 * __useGetPogisDocumentsQuery__
 *
 * To run a query within a React component, call `useGetPogisDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPogisDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPogisDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPogisDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetPogisDocumentsQuery, GetPogisDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPogisDocumentsQuery, GetPogisDocumentsQueryVariables>(GetPogisDocumentsDocument, options);
      }
export function useGetPogisDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPogisDocumentsQuery, GetPogisDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPogisDocumentsQuery, GetPogisDocumentsQueryVariables>(GetPogisDocumentsDocument, options);
        }
export type GetPogisDocumentsQueryHookResult = ReturnType<typeof useGetPogisDocumentsQuery>;
export type GetPogisDocumentsLazyQueryHookResult = ReturnType<typeof useGetPogisDocumentsLazyQuery>;
export type GetPogisDocumentsQueryResult = Apollo.QueryResult<GetPogisDocumentsQuery, GetPogisDocumentsQueryVariables>;
export const CreatePogisDocumentDocument = gql`
    mutation CreatePogisDocument($input: CreatePogisDocumentInput!) {
  createPogisDocument(input: $input) {
    pogisDocument {
      id
    }
  }
}
    `;
export type CreatePogisDocumentMutationFn = Apollo.MutationFunction<CreatePogisDocumentMutation, CreatePogisDocumentMutationVariables>;

/**
 * __useCreatePogisDocumentMutation__
 *
 * To run a mutation, you first call `useCreatePogisDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePogisDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPogisDocumentMutation, { data, loading, error }] = useCreatePogisDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePogisDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePogisDocumentMutation, CreatePogisDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePogisDocumentMutation, CreatePogisDocumentMutationVariables>(CreatePogisDocumentDocument, options);
      }
export type CreatePogisDocumentMutationHookResult = ReturnType<typeof useCreatePogisDocumentMutation>;
export type CreatePogisDocumentMutationResult = Apollo.MutationResult<CreatePogisDocumentMutation>;
export type CreatePogisDocumentMutationOptions = Apollo.BaseMutationOptions<CreatePogisDocumentMutation, CreatePogisDocumentMutationVariables>;
export const UpdatePogisDocumentDocument = gql`
    mutation UpdatePogisDocument($input: UpdatePogisDocumentInput!) {
  updatePogisDocument(input: $input) {
    pogisDocument {
      id
    }
  }
}
    `;
export type UpdatePogisDocumentMutationFn = Apollo.MutationFunction<UpdatePogisDocumentMutation, UpdatePogisDocumentMutationVariables>;

/**
 * __useUpdatePogisDocumentMutation__
 *
 * To run a mutation, you first call `useUpdatePogisDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePogisDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePogisDocumentMutation, { data, loading, error }] = useUpdatePogisDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePogisDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePogisDocumentMutation, UpdatePogisDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePogisDocumentMutation, UpdatePogisDocumentMutationVariables>(UpdatePogisDocumentDocument, options);
      }
export type UpdatePogisDocumentMutationHookResult = ReturnType<typeof useUpdatePogisDocumentMutation>;
export type UpdatePogisDocumentMutationResult = Apollo.MutationResult<UpdatePogisDocumentMutation>;
export type UpdatePogisDocumentMutationOptions = Apollo.BaseMutationOptions<UpdatePogisDocumentMutation, UpdatePogisDocumentMutationVariables>;